<template>
    <div>
        <router-view class="full-height-layout" :carrier-data="carrierData" ref="routeview"></router-view>
        <p v-if="!isAllowedTo('getCarrierById')">Not allowed to see this view</p>
    </div>
</template>

<script>
import CONFIG from '@root/config'

export default {
    name: 'CarrierView',
    data () {
        return {
            carrierData: null,
        }
    },

    computed: {
        breadcrumbElement () {
            return {
                name: (this.carrierData && this.carrierData.carrierName ? this.carrierData.carrierName : '...'),
            }
        },
    },

    created () {
        if (!this.isAllowedTo('getCarrierById')) {
            return
        }
        const getCarrierUrl = CONFIG.API.ROUTES.CARRIERS.GET.replace('{carrierId}', this.$route.params.carrierId)

        this.loading = true
        this.addJob(getCarrierUrl)
        this.$api.get(getCarrierUrl).then(({ data }) => {
            this.carrierData = data.data
        }).catch(({ data }) => {
            this.$root.$emit('notification:global', {
                message: `Getting carrier service levels failed. Please try again.`,
                type: 'error',
                errors: data,
            })
        }).finally(() => {
            this.finishJob(getCarrierUrl)
            this.loading = false
        })
    },
}
</script>
